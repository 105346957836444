import React, { useEffect, useRef, useState } from 'react';
import Avatar from './Avatar';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import MenuItem from './MenuItem';
import { ParsedToken, TextShrinker } from '../services/Helpers';
import { Logout } from '../services/AuthService';
import { profileMenuItems } from '../config/dataScheme';

const TopBarProfile = () => {
  const ref = useRef(null);

  const [profileMenuStatus, setProfileMenuStatus] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setProfileMenuStatus(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [profileMenuStatus]);

  return (
    <div className='profile' ref={ref}>
      <div
        className='profile-option'
        onClick={() => setProfileMenuStatus(!profileMenuStatus)}>
        <div className='name-content'>
          <h3>
            <TextShrinker
              text={ParsedToken().fullName}
              count={10}
              tooltip={false}
            />
          </h3>
          <p>
            <TextShrinker
              text={ParsedToken().email}
              count={10}
              tooltip={false}
            />
          </p>
        </div>
        <div className='profile-pic'>
          <Avatar name={ParsedToken().fullName} />
        </div>
      </div>
      <div className={`profile-drop-down ${profileMenuStatus ? 'show' : ''}`}>
        <div className='profile-menu'>
          {profileMenuItems &&
            profileMenuItems.map((menuItem, index) => (
              <MenuItem item={menuItem} key={index} />
            ))}

          <span onClick={Logout}>
            <MenuItem
              item={{
                title: 'Logout',
                icon: <RiLogoutCircleRLine />,
              }}
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default TopBarProfile;
