// const prefix = 'api/';

export const api_routes = {
  wallets: 'bank',
  categories: 'category',
  transactions: 'transaction',
  friends: 'friend',
  transfers: 'transfer',
  bankTransfer: 'bank/transfer',
  mail: 'mail',
};
