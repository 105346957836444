import React from 'react';

const PreLoader = () => {
  return (
    <div id='preloader'>
      <section>
        <div className='content'>
          <h2>EXP</h2>
          <h2>EXP</h2>
        </div>
        <h3>Track where your money goes</h3>
      </section>
    </div>
  );
};

export default PreLoader;
