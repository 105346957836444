import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../services/AxiosInstance';
import { api_routes } from '../config/api.routes';
import { Alert } from '../services/AlertService';
import { addExpiryTime } from '../services/Helpers';

const initialState = {
  status: 'idle', // idle, loading, succeeded, failed
  error: null,
  submitstatus: 'idle',
  fetchedAt: null,
  data: [],
};

export const fetchData = createAsyncThunk('wallets/fetchData', async () => {
  const response = await axiosInstance.get(`${api_routes.wallets}`);
  return response.data;
});

export const addData = createAsyncThunk(
  'wallets/addData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`${api_routes.wallets}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const editData = createAsyncThunk(
  'wallets/editData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${api_routes.wallets}/${data.id}`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const deleteData = createAsyncThunk(
  'wallets/deleteData',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        `${api_routes.wallets}/${id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message);
    }
  }
);
export const statusChange = createAsyncThunk(
  'wallets/statusChange',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${api_routes.wallets}/status/${data?.id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.msg);
    }
  }
);
export const primaryChange = createAsyncThunk(
  'wallets/primaryChange',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(
        `${api_routes.wallets}/primary/${data?.id}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.msg);
    }
  }
);

export const wallets = createSlice({
  name: 'wallets',
  initialState,
  reducers: {
    updateData: (state, action) => {
      const id = action.payload.id;
      const data = state.data.map((item) => {
        if (item.id === id) {
          return action.payload;
        }
        return item;
      });
      state.data = data;
    },
    updatePrimary: (state, action) => {
      const id = action.payload.id;
      const data = state.data.map((item) => {
        if (item.id === id) {
          return { ...action.payload, isPrimary: true };
        }
        return { ...item, isPrimary: false };
      });
      state.data = data;
    },
    destroyData: (state, action) => {
      const id = action.payload;
      const data = state.data.filter((item) => item.id !== id);
      state.data = data;
    },
  },
  extraReducers: {
    [fetchData.pending]: (state, action) => {
      state.status = 'loading';
    },
    [fetchData.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.fetchedAt = addExpiryTime();
    },
    [fetchData.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    },
    [addData.pending]: (state, action) => {
      state.submitstatus = 'submitting';
    },
    [addData.fulfilled]: (state, action) => {
      state.submitstatus = 'succeeded';
      Alert('success', action.payload.message);
      state.data.push(action.payload.data);
    },
    [addData.rejected]: (state, action) => {
      state.submitstatus = 'failed';
      state.error = action.payload;
      Alert('error', action.payload);
    },
    [editData.pending]: (state, action) => {
      state.submitstatus = 'submitting';
    },
    [editData.fulfilled]: (state, action) => {
      state.submitstatus = 'succeeded';
      Alert('success', action.payload.message);
    },
    [editData.rejected]: (state, action) => {
      state.submitstatus = 'failed';
      state.error = action.payload;
      Alert('error', action.payload);
    },
    [deleteData.pending]: (state, action) => {
      state.submitstatus = 'submitting';
    },
    [deleteData.fulfilled]: (state, action) => {
      state.submitstatus = 'succeeded';
      Alert('success', action.payload.message);
    },
    [deleteData.rejected]: (state, action) => {
      state.submitstatus = 'failed';
      state.error = action.payload;
      Alert('error', action.payload);
    },
    [statusChange.pending]: (state, action) => {
      state.submitstatus = 'submitting';
    },
    [statusChange.fulfilled]: (state, action) => {
      state.submitstatus = 'succeeded';
      Alert('success', action.payload.msg);
    },
    [statusChange.rejected]: (state, action) => {
      state.submitstatus = 'failed';
      state.error = action.payload;
      Alert('error', action.payload);
    },
    [primaryChange.pending]: (state, action) => {
      state.submitstatus = 'submitting';
    },
    [primaryChange.fulfilled]: (state, action) => {
      state.submitstatus = 'succeeded';
      Alert('success', action.payload.msg);
    },
    [primaryChange.rejected]: (state, action) => {
      state.submitstatus = 'failed';
      state.error = action.payload;
      Alert('error', action.payload);
    },
  },
});

export const { updateData, destroyData, updatePrimary } = wallets.actions;

export default wallets.reducer;
