import React, { useEffect, useRef, useState } from 'react';
import { VscBell } from 'react-icons/vsc';
import NotificationItem from './NotificationItem';

const TopBarNotification = () => {
  const ref = useRef(null);
  const notificationItems = [
    {
      title: 'Transfer Completed',
      desc: 'Transfer to John Doe from your account is successfully completed',
      type: 'transfer-out',
      isRead: false,
    },
    {
      title: 'Transfer Completed',
      desc: 'Transfer From John Doe Rs. 200 is added',
      type: 'transfer-in',
      isRead: false,
    },
    {
      title: 'Plan Created',
      desc: 'The Plan Chennai-Trip is Created, you can view transactions now',
      type: 'plan-creation',
      isRead: false,
    },
    {
      title: 'Made Plan Admin',
      desc: 'You are the admin for Chennai-Trip you can add transactions now',
      type: 'plan-admin',
      isRead: false,
    },
  ];
  const [notificationMenuStatus, setNotificationMenuStatus] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setNotificationMenuStatus(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [notificationMenuStatus]);

  return (
    <div className='notification' ref={ref}>
      <div
        className='notification-option'
        onClick={() => setNotificationMenuStatus(!notificationMenuStatus)}>
        <div className='notification-icon'>
          <VscBell />
          {notificationItems && notificationItems.length > 0 && <span></span>}
        </div>
      </div>
      <div
        className={`notification-drop-down ${
          notificationMenuStatus ? 'show' : ''
        }`}>
        {notificationItems && notificationItems.length > 0 ? (
          notificationItems.map((notificationItem, index) => (
            <NotificationItem key={index} notification={notificationItem} />
          ))
        ) : (
          <div className='no-notifications'>
            <span> No new notifications </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopBarNotification;
