import { configureStore } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import categorySlice from './categorySlice';
import layoutSlice from './layoutSlice';
import transactionSlice from './transactionSlice';
import walletSlice from './walletSlice';
import friendSlice from './friendSlice';
import transferSlice from './transferSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    layout: layoutSlice,
    wallet: walletSlice,
    category: categorySlice,
    transaction: transactionSlice,
    friend: friendSlice,
    transfer: transferSlice,
  },
});
