import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CategoryIcon from '../components/CategoryIcon';
import { defaultCategoryScheme } from '../config/dataScheme';
import jwtDecode from 'jwt-decode';

export const isStateExpired = (fetchedAt) => {
  if (fetchedAt) {
    const expiryTime = fetchedAt;
    const now = moment().unix();
    const diff = now - expiryTime;
    if (diff >= 0) {
      return true;
    }

    return false;
  }
  return true;
};

export const addExpiryTime = () => {
  return moment().add('3', 'minutes').unix();
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = [
    'Bytes',
    'KiB',
    'MiB',
    'GiB',
    'TiB',
    'PiB',
    'EiB',
    'ZiB',
    'YiB',
  ];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const TextShrinker = ({
  text = '',
  count = 15,
  placement = 'bottom',
  tooltip = true,
}) => {
  return (
    <>
      {text?.length > count ? (
        <>
          {tooltip ? (
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => <Tooltip {...props}>{text}</Tooltip>}
              placement={placement}>
              <span>
                {text.substring(0, count)}
                <span className='text-shrinker-ellipsis'>...</span>
              </span>
            </OverlayTrigger>
          ) : (
            <span>
              {text.substring(0, count)}
              <span className='text-shrinker-ellipsis'>...</span>
            </span>
          )}
        </>
      ) : (
        text
      )}
    </>
  );
};

export const CurrentObjFinderByID = (data, id) => {
  return data.find((da) => da.id === id);
};

export const CategoryHelper = (currentData) => {
  switch (currentData?.type) {
    case 'income':
    case 'expense':
      if (currentData?.isTransfer)
        return (
          <div className='category-table'>
            <CategoryIcon category={defaultCategoryScheme.transfer} />
            <b>{defaultCategoryScheme?.transfer?.name} </b>
          </div>
        );
      if (currentData?.isSelfTransfer)
        return (
          <div className='category-table'>
            <CategoryIcon category={defaultCategoryScheme.selftransfer} />
            <b>{defaultCategoryScheme?.selftransfer?.name} </b>
          </div>
        );
      if (currentData?.isPlan)
        return (
          <div className='category-table'>
            <CategoryIcon category={defaultCategoryScheme.plantransfer} />
            <b>{defaultCategoryScheme?.plantransfer?.name} </b>
          </div>
        );
      if (currentData.categories_details !== null) {
        return (
          <div className='category-table'>
            <CategoryIcon category={currentData.categories_details} />
            <b>{currentData.categories_details?.name}</b>
          </div>
        );
      } else {
        return (
          <div className='category-table'>
            <CategoryIcon category={defaultCategoryScheme.uncategorized} />
            <b>{defaultCategoryScheme?.uncategorized?.name}</b>
          </div>
        );
      }

    case 'initialdeposit':
      return (
        <div className='category-table'>
          <CategoryIcon category={defaultCategoryScheme.initialdeposit} />
          <b>{defaultCategoryScheme?.initialdeposit?.name}</b>
        </div>
      );

    default:
      if (currentData.categories_details !== null) {
        return (
          <div className='category-table'>
            <CategoryIcon category={currentData.categories_details} />
            <b>{currentData.categories_details?.name}</b>
          </div>
        );
      } else {
        return (
          <div className='category-table'>
            <CategoryIcon category={defaultCategoryScheme.uncategorized} />
            <b>{defaultCategoryScheme?.uncategorized?.name}</b>
          </div>
        );
      }
  }
};

export const TransactionCount = (value) => {
  if (Number(value) === 0) return `No Transactions`;
  if (Number(value) > 1) return `${value} Transactions`;
  if (Number(value) <= 1) return `${value} Transaction`;
};

export const ParsedToken = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('Unauthorized Access');
    window.location.href = '/login';
    return;
  }
  const parsedToken = jwtDecode(token);
  return {
    ...parsedToken,
    fullName: `${parsedToken?.fname?.toUpperCase()} ${parsedToken?.lname?.toUpperCase()}`,
  };
};

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
};

export const FormatDate = (date) =>
  moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
export const FormatDateYearFirst = (date) =>
  moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
