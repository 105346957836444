import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import LeftBar from '../components/LeftBar';
import TopBar from '../components/TopBar';
import { isMobile } from 'react-device-detect';
import MobileNavBar from '../components/MobileNavBar';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSideModal } from '../redux/layoutSlice';

const Home = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./TransactionScreen')), 1000);
  });
});
const WalletScreen = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./WalletScreen')), 1000);
  });
});
const CategoryScreen = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./CategoryScreen')), 1000);
  });
});
const TransactionScreen = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./TransactionScreen')), 1000);
  });
});
const FriendScreen = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./FriendScreen')), 1000);
  });
});
const TransferScreen = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./TransferScreen')), 1000);
  });
});

const Index = () => {
  const menuItems = [
    {
      path: '/home',
      element: <Home />,
    },
    {
      path: '/',
      element: <Home />,
    },
    {
      path: '/wallet',
      element: <WalletScreen />,
    },
    {
      path: '/categories',
      element: <CategoryScreen />,
    },
    {
      path: '/transactions',
      element: <TransactionScreen />,
    },
    {
      path: '/friends',
      element: <FriendScreen />,
    },
    {
      path: '/transfers',
      element: <TransferScreen />,
    },
  ];
  const { sideModalStatus, sideNavStatus } = useSelector(
    (state) => state.layout
  );
  const dispatch = useDispatch();

  return (
    <>
      {!isMobile ? <TopBar sideNavStatus={sideNavStatus} /> : <MobileNavBar />}

      <div className='main-wrapper'>
        {sideModalStatus && (
          <div
            className='overlay'
            onClick={() => dispatch(toggleSideModal())}></div>
        )}
        {!isMobile && <LeftBar sideNavStatus={sideNavStatus} />}

        <div className={`main-layout ${sideNavStatus ? 'opened' : 'closed'}`}>
          <div className='content-wrapper'>
            <Routes>
              {menuItems &&
                menuItems.map(({ path, element }, index) => (
                  <Route path={path} element={element} />
                ))}
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
