import React, { lazy, Suspense } from 'react';

/// Style
import 'bootstrap/dist/css/bootstrap.min.css';
import './app/assets/css/style.css';
import { Route, Routes, Navigate } from 'react-router-dom';

import { isAuthenticated } from './app/services/AuthService';
import Index from './app/screens';
import PreLoader from './app/components/PreLoader';

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./app/screens/LoginScreen')), 1000);
  });
});
const Register = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import('./app/screens/RegisterScreen')), 500);
  });
});

const App = () => {
  let routes = (
    <Routes>
      <Route path='/login' element={<Login />} />
      <Route path='/register' element={<Register />} />
      <Route path='*' element={<Navigate to='/login' replace />} />
    </Routes>
  );
  if (isAuthenticated()) {
    return (
      <>
        <Suspense fallback={<PreLoader />}>
          <Index />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className='vh-100 auth-layout'>
        <Suspense fallback={<PreLoader />}>{routes}</Suspense>
      </div>
    );
  }
};

export default App;
