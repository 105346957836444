import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Alert, handleGoogleError } from '../services/AlertService';
import { handleAPILogin, handleAPIRegister } from '../services/AuthService';

import jwtDecode from 'jwt-decode';

// Firebase
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from 'firebase/auth';

const initialState = {
  submitstatus: 'idle',
  googlestatus: 'idle',
};

/* Creating a thunk that will be used to login a user. */
export const StandardLogin = createAsyncThunk(
  'auth/StandardLogin',
  async (data, { rejectWithValue }) => {
    const auth = getAuth();
    try {
      const response = await signInWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      const token = await response._tokenResponse.idToken;
      const googleToken = jwtDecode(token);
      Alert('success', `Authenticated as ${googleToken.email}`);
      const apiRes = await handleAPILogin(token);
      if (!apiRes) return rejectWithValue({ code: 'auth/api-error' });
      return apiRes;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* Creating a thunk that will be used to login a user. */
export const SocialLogin = createAsyncThunk(
  'auth/SocialLogin',
  async (data, { rejectWithValue }) => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const response = await firebase.auth().signInWithPopup(provider);
      const token = await response.user._delegate.accessToken;

      const googleToken = jwtDecode(token);
      Alert('success', `Authenticated as ${googleToken.email}`, true);
      var apiRes = await handleAPILogin(token);
      if (!apiRes) return rejectWithValue({ code: 'auth/api-error' });
      return apiRes;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* Creating a thunk that will be used to register a user. */
export const StandardRegister = createAsyncThunk(
  'auth/StandardRegister',
  async (data, { rejectWithValue }) => {
    const auth = getAuth();
    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      const token = await response._tokenResponse.idToken;
      const googleToken = jwtDecode(token);
      Alert('success', `Registered as ${googleToken.email}`);
      const apiRes = await handleAPIRegister(
        token,
        data.fname,
        data.lname,
        data.email
      );
      if (!apiRes) return rejectWithValue({ code: 'auth/api-error' });
      return apiRes;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* Creating a thunk that will be used to register a user. */
export const SocialRegister = createAsyncThunk(
  'auth/SocialRegister',
  async (data, { rejectWithValue }) => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const response = await firebase.auth().signInWithPopup(provider);
      const token = await response.user._delegate.accessToken;
      const googleToken = jwtDecode(token);
      Alert('success', `Authenticated as ${googleToken.email}`, true);
      var apiRes = await handleAPIRegister(token);
      if (!apiRes) return rejectWithValue({ code: 'auth/api-error' });
      return apiRes;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/* Creating a slice of the Redux store. */
export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    [StandardLogin.pending]: (state, action) => {
      state.submitstatus = 'submitting';
    },
    [StandardLogin.fulfilled]: async (state, action) => {
      localStorage.setItem('token', action.payload);
      state.submitstatus = 'succeeded';
    },
    [StandardLogin.rejected]: (state, action) => {
      state.submitstatus = 'failed';
      handleGoogleError(action.payload);
    },
    [SocialLogin.pending]: (state, action) => {
      state.googlestatus = 'submitting';
    },
    [SocialLogin.fulfilled]: async (state, action) => {
      localStorage.setItem('token', action.payload);
      state.googlestatus = 'succeeded';
    },
    [SocialLogin.rejected]: (state, action) => {
      state.googlestatus = 'failed';
      handleGoogleError(action.payload);
    },
    [StandardRegister.pending]: (state, action) => {
      state.submitstatus = 'submitting';
    },
    [StandardRegister.fulfilled]: async (state, action) => {
      localStorage.setItem('token', action.payload);
      state.submitstatus = 'succeeded';
    },
    [StandardRegister.rejected]: (state, action) => {
      state.submitstatus = 'failed';
      handleGoogleError(action.payload);
    },
    [SocialRegister.pending]: (state, action) => {
      state.googlestatus = 'submitting';
    },
    [SocialRegister.fulfilled]: async (state, action) => {
      localStorage.setItem('token', action.payload);
      state.googlestatus = 'succeeded';
    },
    [SocialRegister.rejected]: (state, action) => {
      state.googlestatus = 'failed';
      handleGoogleError(action.payload);
    },
  },
});

export default auth.reducer;
