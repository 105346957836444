import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  sideModalStatus: false,
  sideNavStatus: false,
};

export const layout = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleSideModal: (state) => {
      state.sideModalStatus = !state.sideModalStatus;
    },
    toggleSideNav: (state) => {
      state.sideNavStatus = !state.sideNavStatus;
    },
  },
});

export const { toggleSideModal, toggleSideNav } = layout.actions;

export default layout.reducer;
