import React from 'react';
import { colorScheme, iconScheme } from '../config/dataScheme';

const CategoryIcon = ({ category }) => {
  const icon = iconScheme[category?.icon - 1]?.name;
  const color = colorScheme[category?.color - 1]?.code;

  return (
    <>
      <div className='category-icon'>
        <div className='icon-color' style={{ backgroundColor: color }}>
          {icon}
        </div>
      </div>
    </>
  );
};

export default CategoryIcon;
