import React, { useEffect, useState } from 'react';
import { Logo } from '../assets/images';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoCloseSharp } from 'react-icons/io5';
import Avatar from './Avatar';
import { ParsedToken } from '../services/Helpers';
import { useLocation } from 'react-router-dom';

import MenuItem from './MenuItem';
import { logoutMenu, menuItems, profileMenuItems } from '../config/dataScheme';

const MobileNavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const location = useLocation();
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let newPath = window.location.pathname;
    newPath = newPath.split('/');
    newPath = newPath.filter(function (pa) {
      return pa !== '';
    });
    newPath = newPath[0] ? `/${newPath[0]}` : '/';
    setPath(newPath);
  }, [location]);

  const handleOverlayClick = () => {
    setIsMenuOpen(false);
    setIsProfileMenuOpen(false);
  };
  const handleProfileMenuClick = () => {
    setIsMenuOpen(false);
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };
  const handleMenuClick = () => {
    setIsProfileMenuOpen(false);
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <div className='mobile-navbar'>
        <div className='navbar-wrapper'>
          <div className='logo'>
            <img
              src={Logo}
              alt='Logo'
              className='img img-responsive logo'
              draggable='false'
            />
          </div>
          <div className='right'>
            <div className='avatar' onClick={handleProfileMenuClick}>
              <Avatar name={ParsedToken()?.fullName} />
            </div>
            <div className='toggler-bar'>
              {isMenuOpen ? (
                <IoCloseSharp onClick={handleMenuClick} />
              ) : (
                <GiHamburgerMenu onClick={handleMenuClick} />
              )}
            </div>
          </div>
        </div>

        {isMenuOpen && (
          <div className={`menu-bar  ${isMenuOpen && 'show'}`}>
            {menuItems &&
              menuItems.map((menuItem, index) => (
                <MenuItem
                  mobile
                  handleToggle={setIsMenuOpen}
                  item={menuItem}
                  key={index}
                  active={menuItem.path === path ? true : false}
                />
              ))}
          </div>
        )}

        {isProfileMenuOpen && (
          <div className={`menu-bar  ${isProfileMenuOpen && 'show'}`}>
            {profileMenuItems &&
              profileMenuItems.map((menuItem, index) => (
                <MenuItem
                  mobile
                  handleToggle={setIsProfileMenuOpen}
                  item={menuItem}
                  key={index}
                  active={menuItem.path === path ? true : false}
                />
              ))}
            {logoutMenu}
          </div>
        )}
      </div>
      {isMenuOpen && (
        <div className='main-overlay' onClick={handleOverlayClick}></div>
      )}
      {isProfileMenuOpen && (
        <div className='main-overlay' onClick={handleOverlayClick}></div>
      )}
    </>
  );
};

export default MobileNavBar;
