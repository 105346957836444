import React from 'react';
import { IoSettingsOutline } from 'react-icons/io5';
import { BiFullscreen } from 'react-icons/bi';
import {
  TbLayoutSidebarLeftCollapse,
  TbLayoutSidebarRightCollapse,
} from 'react-icons/tb';
import { useDispatch } from 'react-redux';
import { toggleSideNav } from '../redux/layoutSlice';
import TopBarNotification from './TopBarNotification';
import TopBarProfile from './TopBarProfile';

const TopBar = ({ sideNavStatus }) => {
  const dispatch = useDispatch();

  const handleFullScreen = () => {
    if (document.fullscreenElement === null) {
      var elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div className='top-nav-bar'>
      <div className={`toggle-button ${sideNavStatus ? 'opened' : 'closed'}`}>
        <button onClick={() => dispatch(toggleSideNav())}>
          {sideNavStatus ? (
            <TbLayoutSidebarRightCollapse />
          ) : (
            <TbLayoutSidebarLeftCollapse />
          )}
        </button>
      </div>

      <div className='d-flex align-items-center'>
        <div className='settings' onClick={handleFullScreen}>
          <div className='settings-option'>
            <div className='settings-icon'>
              <BiFullscreen />
            </div>
          </div>
        </div>
        <div className='settings'>
          <div className='settings-option'>
            <div className='settings-icon'>
              <IoSettingsOutline />
            </div>
          </div>
        </div>
        <TopBarNotification />
        <TopBarProfile />
      </div>
    </div>
  );
};

export default TopBar;
