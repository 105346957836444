import axios from 'axios';
import { API_URL } from '../config/config';
import { Alert } from './AlertService';

const getToken = () => {
  const token = localStorage.getItem('token');
  return token;
};

const logoutToken = () => {
  // Alert('error', 'You have been logged out due to inactivity');
  alert('You have been logged out due to inactivity');
  localStorage.removeItem('token');
  window.location.href = '/login';
};

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    config.baseURL = API_URL;
    const token = getToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response?.status === 401) {
      logoutToken();
    }

    if (error.message === 'Network Error') {
      Alert('error', 'Network Error');
    }

    return Promise.reject(error);
  }
);

const exportedObject = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch,
};

export default exportedObject;
