import React, { useEffect, useState } from 'react';
import { Logo } from '../assets/images';

import MenuItem from './MenuItem';
import { useLocation } from 'react-router-dom';
import { menuItems } from '../config/dataScheme';

const LeftBar = ({ sideNavStatus }) => {
  const location = useLocation();
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let newPath = window.location.pathname;
    newPath = newPath.split('/');
    newPath = newPath.filter(function (pa) {
      return pa !== '';
    });
    newPath = newPath[0] ? `/${newPath[0]}` : '/';
    setPath(newPath);
  }, [location]);

  return (
    <div className={`left-side-bar ${sideNavStatus ? 'opened' : 'closed'}`}>
      <img
        src={Logo}
        alt='Logo'
        className='img img-responsive logo'
        draggable='false'
      />
      <div className='menu-bar'>
        {menuItems &&
          menuItems.map((menuItem, index) => (
            <MenuItem
              item={menuItem}
              key={index}
              active={menuItem.path === path ? true : false}
            />
          ))}
      </div>

      <div className='copyright'>
        <p>&copy; All Rights Reserved</p>
      </div>
    </div>
  );
};

export default LeftBar;
